const LOG_PREFIX = '[OCM][AniView] '

module.exports = class AniView {
    constructor(utils, config) {
        this.utils = utils
        this.config = config
        this.aniview_config = (this.utils.is_mobile) ? config.services.aniview.mobile : config.services.aniview.desktop
        this.aniview_config['debug'] = this.config.services.aniview.debug
        this.ADSQ_TARGETING = null
    }

    run() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Running...')
        }

        if (this.utils.allowPageType(this.aniview_config.rules.page_types)) {
            this.createPlayerElement()
        } else if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + "Page type doesn't match the selected ones: ", this.aniview_config.rules.page_types)
        }
    }

    createPlayerElement() {
        this.utils.contentLoaded().then(() => {
            this.inject()
        })
    }

    inject() {
        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Trying to inject player element')
        }

        let node = this.utils.determineInjectionTarget(this.aniview_config.rules.selector, this.aniview_config.rules.position, this.aniview_config.rules.count_gt, this.aniview_config.rules.word_count, this.aniview_config.rules.words_gt)

        if (this.config.debug || this.aniview_config.debug) {
            console.info(LOG_PREFIX + 'node found: ', node)
        }

        if (!node) {
            if (this.config.debug || this.aniview_config.debug) {
                console.info(LOG_PREFIX + 'node NOT found, stopping process', node)
            }
            return;
        }

        let tagScript = document.createElement('script')
        tagScript.id = `AV${this.aniview_config.playerTagId}`
        tagScript.type = "text/javascript"
        tagScript.src = `https://tg1.aniview.com/api/adserver/spt?AV_PUBLISHERID=${this.aniview_config.publisher}&AV_TAGID=${this.aniview_config.playerTagId}`
        tagScript.async = "true"
        tagScript.setAttribute('data-config-api', 'avConfig')
        tagScript.setAttribute('data-player-api', 'avPassback')

        let ocmPlayer = this.utils.window.document.createElement('div')
        ocmPlayer.classList.add('ocm-player')
        if (this.aniview_config.classes) {
            ocmPlayer.classList.add(this.aniview_config.classes)
        }
        if (this.aniview_config.styles) {
            ocmPlayer.style = this.aniview_config.styles
        }

        // Check for existence of chirp.js or hb adsqRTDModuleEnabled. If any of those are true, wait for window.ADSQ
        if (window.top.document.querySelector('script[src*="chirp.js"]') || this.utils.config.services.header_bidding.adsqRTDModuleEnabled) {
            this.utils.waitFor('ADSQ.response', () => {
                let classifiedAs = []
                let response = null
                response = window.top.ADSQ?.response
                if (response) {
                    if (response.classified_as) {
                        response.classified_as.forEach((classification) => {
                            let split = []
                            split = classification.split('/')
                            let tmp = null
                            split.forEach((term) => {
                                if (!tmp) {
                                    tmp = term
                                } else {
                                    tmp += '/' + term
                                }

                                classifiedAs.push(tmp)
                            })
                        })
                    }

                    this.ADSQ_TARGETING = {
                        adsq_bs: (response.brandSafe) ? (response.brandSafe === true) ? 'Yes' : 'No' : 'notEvaluated',
                        adsq_bs_class: (classifiedAs.length) ? [...new Set(classifiedAs)] : [],
                        adsq_bs_ne: response.entities || [],
                        adsq_bs_sent: response.sentiment || '',
                        hostname: window.top.location.hostname.replace('www.', ''),
                        city: response?.geo?.city || '',
                        country: response?.geo?.country || '',
                    }

                    tagScript.src += `&AV_CUSTOM1=${this.ADSQ_TARGETING.adsq_bs}`
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_class.length) ? `&AV_CUSTOM2=${encodeURIComponent(this.ADSQ_TARGETING.adsq_bs_class.join(','))}` : ''
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_sent !== '') ? `&AV_CUSTOM3=${this.ADSQ_TARGETING.adsq_bs_sent}` : ''
                    tagScript.src += (this.ADSQ_TARGETING.adsq_bs_ne.length) ? `&AV_CUSTOM4=${encodeURIComponent(this.ADSQ_TARGETING.adsq_bs_ne.join(','))}` : ''
                }

                this.appendTag(node, ocmPlayer, tagScript)
            }, 50, () => {
                this.appendTag(node, ocmPlayer, tagScript)
            })
        } else {
            this.appendTag(node, ocmPlayer, tagScript)
        }
    }

    appendTag(node, ocmPlayer, tagScript) {
        this.utils.injectTag(node, ocmPlayer, this.aniview_config.rules.place)
        ocmPlayer.append(tagScript)

        if (this.config.debug || this.aniview_config.debug) {
            console.log(LOG_PREFIX + 'Selector found and script added')
        }

        this.createPassbacks()
    }

    createPassbacks() {
        this.utils.window.avConfig = (config) => {
            config.openRtb = {
                site: {
                    ext: {
                        data: this.ADSQ_TARGETING
                    }
                }
            }
        }

        this.utils.window.avPassback = (config, player) => {
            if (this.config.debug || this.aniview_config.debug) {
                console.log(LOG_PREFIX + 'Checking if I should run OCM.oipb for specific player')
                console.log(LOG_PREFIX + 'config.tagId !== this.aniview_config.playerTagId', config.tagId, this.aniview_config.playerTagId)
            }

            if (config.tagId !== this.aniview_config.playerTagId) {
                return
            }

            player.on('AdError', (event, eventData) => {
                if (typeof this.utils.window.OCM.oipb == 'function') {
                    this.utils.window.OCM.oipb(event, eventData)
                }
            })
        }
    }
}
