module.exports = async (pageSense, utils, config) => {
    let win = config.hasOwnProperty('use_window_top') ? ( config.use_window_top  ? window.top:  window ) : window.top

    // Service :: Reaper.js
    if (config.services?.reaper?.active) {
        const Reaper = require('../services/reaper')
        const reaper = new Reaper(utils, config)
        reaper.run()
    }
    // -- Service :: Reaper.js

    // Service :: GPT
    if (config.services?.gpt.active) {
        const gpt = require('../services/gpt')
        gpt(config)
    }
    // -- Service :: GPT

    const pageType = await pageSense(utils).then((pageType) => {
        // Set global for usage in services without passing it as param
        win.OCM.pageType = pageType
        return pageType
    })

    // Chrome extension data
    utils.ocmChromeExt(config)
    // -- Chrome extension data

    // Check for adblock
    await utils.isAdBlocked()

    /*** Include below code blocks only if they are enabled in the DSG config ***/

    // Service :: Blockthrough
    if (win.OCM.adBlocked && config.services?.blockthrough.active) {
        const blockthrough = require('../services/blockthrough')
        blockthrough(config)
    }
    // -- Service :: Blockthrough

    // Geolocation
    if (config.services?.geolocation.active) {
        await utils.geolocation()
    }
    // -- Geolocation

    // Service :: Fizz
    if (config.services?.fizz.active) {
        const Fizz = require('../services/fizz')

        utils.contentLoaded().then(() => {
            const fizz = new Fizz(utils, config)
            fizz.run()
        })
    }
    // -- Service :: Fizz

    // Service :: Mobile Web Interstitial
    if (config.services?.mwi.active) {
        const mwi = require('../services/mwi')
        mwi(config, utils)
    }
    // -- Service :: Mobile Web Interstitial

    // Service :: Injected Tags
    if (config.services?.injected_tags.active) {
        const InjectedTags = require('../services/injected_tags')

        const injectedTags = new InjectedTags(utils, config, null)
        injectedTags.run()
    }
    // -- Service :: Injected Tags

    // Service :: Lazyload
    if (config.services?.lazyload.active && utils.allowPageType(config.services?.lazyload.page_types)) {
        const LazyLoad = require('../services/lazyload')
        const ll = new LazyLoad(utils, config)
        await ll.run()
    }
    // -- Service :: Lazyload

    // Service :: UAM
    if (!config.services?.lazyload.active && config.services?.uam.active &&
        config.services?.uam.hasOwnProperty('page_types') &&
        utils.allowPageType(config.services?.uam.page_types)) {
        const UAM = require('../services/uam')
        const uam = new UAM(utils, config)
        uam.init()
        await uam.fetch([])
    }
    // -- Service :: UAM

    // Service :: Header Bidding
    if (!config.services?.lazyload.active && config.services?.header_bidding.active &&
        config.services?.header_bidding.hasOwnProperty('page_types') &&
        utils.allowPageType(config.services?.header_bidding.page_types)) {
        const HeaderBidding = require('../services/header_bidding')
        const hb = new HeaderBidding(utils, config)
        await hb.prepare(false)
    }
    // -- Service :: Header Bidding

    // Service :: Desktop side sticky
    if (config.services?.sticky.active) {
        const Sticky = require('../services/sticky')

        const sticky = new Sticky(utils, config)
        await sticky.run()
    }
    // -- Service :: Desktop side sticky

    // Service :: Mobile inread sticky
    if (config.services?.mobile_inread_sticky.active && utils.is_mobile) {
        const MobileInreadSticky = require('../services/mobile_inread_sticky')

        utils.contentLoaded().then(() => {
            const mis = new MobileInreadSticky(utils, config)
            mis.run()
        })
    }
    // -- Service :: Mobile inread sticky

    // Service :: Engageya
    if (config.services?.engageya.active) {
        let engageya_page_types = (utils.is_mobile) ? config.services?.engageya.mobile.conditions.page_types : config.services?.engageya.desktop.conditions.page_types
        if (utils.allowPageType(engageya_page_types)) {
            const Engageya = require('../services/engageya')

            const engageya = new Engageya(utils, config)
            utils.contentLoaded().then(() => {
                engageya.run()
            })
        }
    }
    // -- Service :: Engageya

    // Outstream Demo
    if (utils.getParameterByName('vast_url')) {
        const Outstream = require('../services/outstream')

        utils.waitFor('teads', () => {
            setTimeout(() => {
                const outstream = new Outstream(config, utils)
                outstream.runDemoInread(utils)
            }, 1000)
        });
    }
    // -- Outstream Demo

    // Service :: Outstream
    // TODO
    // if (config.services.outstream.active) {
    //     const Outstream = require('../services/outstream')
    //
    //     if (pageType === 'Article') {
    //         utils.contentLoaded().then(() => {
    //             const outstream = new Outstream(config, utils)
    //             outstream.run()
    //         })
    //     }
    // }
    // -- Service :: Outstream

    // Service :: Story Teller
    if (config.services?.story_teller.active) {
        const StoryTeller = require('../services/story_teller')

        utils.contentLoaded().then(() => {
            const st = new StoryTeller(utils, config)
            st.run()
        })
    }
    // -- Service :: Story Teller

    // Service :: Instream-hb.js
    if (config.services?.instream_hb.active) {
        const InstreamHB = require('../services/instream_hb')
        utils.contentLoaded().then(() => {
            const instreamHB = new InstreamHB(utils, config)
            instreamHB.run()
        })
    }
    // -- Service :: Instream-hb.js

    // Service :: Instream.js
    if (config.services?.instream.active) {
        const Instream = require('../services/instream')
        utils.contentLoaded().then(() => {
            const instream = new Instream(utils, config)
            instream.run()
        })
    }
    // -- Service :: Instream.js

    // Service :: VI
    if (config.services?.vi.active) {
        let vi_page_types = (utils.is_mobile) ? config.services?.vi.mobile.conditions.page_types : config.services?.vi.desktop.conditions.page_types
        if (utils.allowPageType(vi_page_types)) {
            const VI = require('../services/vi')

            utils.contentLoaded().then(() => {
                const vi = new VI(utils, config)
                vi.run()
            })
        }
    }
    // -- Service :: VI

    // Service :: Primis
    if (config.services?.primis.active) {
        let primis_page_types = (utils.is_mobile) ? config.services?.primis.mobile.conditions.page_types : config.services?.primis.desktop.conditions.page_types
        if (utils.allowPageType(primis_page_types)) {
            const Primis = require('../services/primis')

            utils.contentLoaded().then(() => {
                const primis = new Primis(utils, config)
                primis.run()
            })
        }
    }
    // -- Service :: Primis

    // Service :: InImage.js
    if (config.services?.in_image?.active) {
        const InImage = require('../services/in_image')
        const in_image = new InImage(utils, config)
        in_image.run()
    }
    // -- Service :: InImage.js

    // Service :: AdLightning
    if (config.services?.adlightning?.active) {
        const adLightning = require('../services/adlightning');
        adLightning(config);
    }
    // -- Service :: AdLightning

    // Service :: teadsAnalytics.js
    if (config.services?.teads_analytics?.active) {
        const teadsAnalytics = require('../services/teads_analytics')
        teadsAnalytics(config)
    }
    // -- Service :: teadsAnalytics.js

    // Service :: AniView
    if (config.services?.aniview?.active) {
        const AniView = require('../services/aniview')
        const aniview = new AniView(utils, config)
        aniview.run()
    }
    // -- Service :: AniView
}
