const LOG_PREFIX = "[OCM][Custom] "
const Instream = require('../../services/instream')
const InjectedTags = require('../../services/injected_tags')
window.OCM.it_count = 0

let customCode = (utils) => {
  return new Promise((resolve, reject) => {
    utils.contentLoaded().then(() => {
      runOutstream(utils)
    })

    let originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (utils) {
      this.addEventListener('loadend', reInjectTags);
      originalOpen.apply(this, arguments);
    };

    let reInjectTags = function() {
      if (this.responseURL.indexOf('/partial/?') > -1) {
        window.OCM.it_count++
        utils.config.services.injected_tags.tags[0].div_id += '-' + window.OCM.it_count //inline1
        const it = new InjectedTags(utils, utils.config, null)
        it.run()
      }
    }

    resolve(true)
  });
};

let runOutstream = (utils) => {
  if (utils.config.debug) {
    console.log(LOG_PREFIX + 'Running outstream')
  }

  (function (w, d, s) {
    try {
      d = w.top.document || d;
      w = w.top.document ? w.top : w;
    } catch (e) {
    }

    if (w.teads !== undefined) {
      return;
    }

    let exclude_urls = [
      //{'url': '2935139'}
    ]

    for (var z = 0; z < exclude_urls.length; z++) {
      if (w.location.href.indexOf(exclude_urls[z].url) > -1) {
        return;
      }
    }

    var selector = document.querySelectorAll("#content-main >p")[0];
    if (!selector) {
      return
    }

    var ttag = function () {
      w.teads.page(159155).placement(173732, {
        slider: {allow_corner_position: false, allow_top_position: false},
        "css": "width:100% !important; height:auto !important; margin: 10px auto 25px",
        "format": "inread",
        "slot": {"insertAfter": true, "btf": false, "selector": selector, "minimum": 0}
      }).passback(function () {
        window.top.OCM.oipb = () => {
          ocmPassback(utils, selector)
        }

        utils.config.services.instream.active = true
        const is = new Instream(utils, utils.config)
        is.run()

      }).serve();
    };
    if (w.teads && w.teads.page) {
      ttag();
    } else if (!w.teadsscript) {
      var protocol = w.location.protocol.match(/^https?:$/) ? w.location.protocol : 'https:';
      s.src = protocol + '//a.teads.tv/media/format/v3/teads-format.min.js';
      s.async = true;
      s.onload = ttag;
      w.teadsscript = d.getElementsByTagName('head')[0].appendChild(s);
    }
  })(window, document, document.createElement('script'));
}

let ocmPassback = (utils, selector) => {
  if (!selector) {
    return
  }

  window.googletag.cmd.push(() => {
    let inread = googletag.sizeMapping()
    .addSize([0,0], [[1,1], [300,250]])
    .addSize([360,0], [[1, 1], [300,250],[336,280]])
    .build()

    googletag.defineSlot('/75351959,22845077979/pressaris.gr/inread_pb', [[1, 1], [300, 250], [336, 280]], 'ocm-outstream').defineSizeMapping(inread).addService(googletag.pubads());

    selector.insertAdjacentHTML('afterend', `<div id="ocm-outstream" style="margin-bottom: 26px;text-align:center;"></div>`)
    let div = document.getElementById('ocm-outstream')
    let script = document.createElement('script')
    script.text = `googletag.cmd.push(() => { googletag.display('ocm-outstream'); })`
    div.append(script)
  })
}

module.exports = customCode;