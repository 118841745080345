const LOG_PREFIX = "[OCM][PageSense] "
let pageSense = async (utils) => {
  return new Promise((resolve, reject) => {
    let pageType = 'ROS';
    let subPaths = window.location.pathname.replace("/", "").split("/") || [];
    let customPaths = ['contact','πολιτικη-απορρητου-προστασιασ-προσω','όροι-χρήσης'];
    // Keep only String values in subPaths (removes null and empty)
    subPaths = subPaths.filter(String);
    // The actual page sense goes here
    if (subPaths.length === 0) {
      pageType = "Home";
    } else if (subPaths.length === 1) {
      if (customPaths.indexOf(subPaths[0]) >= 0) {
        pageType = "Custom-1";
      } else {
        pageType = "Article";
      }
    } else if (subPaths.length >= 2 && (subPaths[0] === "category" || subPaths[0] === "tag")){
      pageType = "Category";
    }

    resolve(pageType)
  });
};
module.exports = pageSense;