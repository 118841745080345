const LOG_PREFIX = '[OCM][IAB] '
let iabCompliance = (config) => {
    let win = window.top //config.hasOwnProperty('use_window_top') ? ( config.use_window_top  ? window.top:  window ) : window.top
    win.googletag = win.googletag || {cmd: []}
    return new Promise((resolve, reject) => {
        const maxTries = 60
        let tries = 1

        let end = (result) => {
            if (result) {
                transmuteCmpScripts(config)
                resolve(result)
            } else {
                reject(result)
            }
        }

        let waitForTcfApi = () => {
            if (config.debug || config.services.iab_compliance.debug) {
                console.log(LOG_PREFIX + 'Waiting for tcfapi')
            }
            if (typeof win.__tcfapi === "undefined" && typeof win.__cmp === "undefined") {
                if (config.debug || config.services.iab_compliance.debug) {
                    console.log(LOG_PREFIX + 'tcfapi not found, retrying')
                }
                tries++

                if (tries >= maxTries)  {
                    win.googletag.cmd.push(() => {
                        win.googletag.pubads().setPrivacySettings({
                            nonPersonalizedAds: true
                        })
                    });

                    end(true)
                } else {
                    setTimeout(waitForTcfApi, 100)
                }
            } else {
                if (typeof win.__tcfapi !== "undefined") {
                    win.__tcfapi("addEventListener", 2, function (tcData, success) {
                        if (success && !tcData.eventStatus && !tcData.gdprApplies) {
                            if (config.debug || config.services.iab_compliance.debug) {
                                console.log(LOG_PREFIX + 'GDPR does not apply, run ads normally')
                            }
                            end(true)
                        }

                        if (success && (tcData.eventStatus === "tcloaded" || tcData.eventStatus === "useractioncomplete")) {
                            if (config.debug || config.services.iab_compliance.debug) {
                                console.log(LOG_PREFIX + 'tcfapi found, calculating values')
                            }
                            if (config.use_window_top) {
                                win.OCM.consentData = tcData;
                            }
                            if (!tcData.gdprApplies) {
                                if (config.debug || config.services.iab_compliance.debug) {
                                    console.log(LOG_PREFIX + 'GDPR does not apply, run ads normally')
                                }
                                end(true)
                            } else {
                                if (config.use_window_top) {
                                    win.OCM.gdprApplies = true;
                                }
                                let hasPurposeOneConsent = (tcData.purpose.consents["1"] === true || tcData.purpose.legitimateInterests["1"] === true);
                                let hasPurposeTwoConsent = (tcData.purpose.consents["2"] === true || tcData.purpose.legitimateInterests["2"] === true);
                                let hasPurposeThreeConsent = (tcData.purpose.consents["3"] === true || tcData.purpose.legitimateInterests["3"] === true);
                                let hasPurposeFourConsent = (tcData.purpose.consents["4"] === true || tcData.purpose.legitimateInterests["4"] === true);
                                let hasPurposeSevenConsent = (tcData.purpose.consents["7"] === true || tcData.purpose.legitimateInterests["7"] === true);
                                let hasPurposeNineConsent = (tcData.purpose.consents["9"] === true || tcData.purpose.legitimateInterests["9"] === true);
                                let hasPurposeTenConsent = (tcData.purpose.consents["10"] === true || tcData.purpose.legitimateInterests["10"] === true);
                                let hasGoogleVendorConsent = (tcData.vendor.consents["755"] && tcData.vendor.legitimateInterests["755"])
                                let hasGoogleConsent = false

                                let serveNonPersonalized = true;
                                let rdp = false;

                                if (hasGoogleVendorConsent && hasPurposeOneConsent && hasPurposeTwoConsent && hasPurposeSevenConsent && hasPurposeNineConsent && hasPurposeTenConsent) {
                                    hasGoogleConsent = true
                                }

                                if (hasPurposeOneConsent && hasPurposeTwoConsent && hasPurposeThreeConsent &&
                                    hasPurposeFourConsent && hasPurposeSevenConsent && hasPurposeNineConsent && hasPurposeTenConsent &&
                                    hasGoogleConsent) {
                                    serveNonPersonalized = false
                                } else if (hasPurposeOneConsent && hasPurposeTwoConsent && !hasPurposeThreeConsent &&
                                    !hasPurposeFourConsent && hasPurposeSevenConsent && hasPurposeNineConsent && hasPurposeTenConsent &&
                                    hasGoogleConsent) {
                                    serveNonPersonalized = true
                                }

                                if (!hasPurposeNineConsent) {
                                    rdp = false
                                }

                                if (config.use_window_top) {
                                    win.OCM.hasPurposeOneConsent = hasPurposeOneConsent
                                    win.OCM.hasPurposeTwoConsent = hasPurposeTwoConsent
                                    win.OCM.hasPurposeThreeConsent = hasPurposeThreeConsent
                                    win.OCM.hasPurposeFourConsent = hasPurposeFourConsent
                                    win.OCM.hasPurposeSevenConsent = hasPurposeSevenConsent
                                    win.OCM.hasPurposeNineConsent = hasPurposeNineConsent
                                    win.OCM.hasPurposeTenConsent = hasPurposeTenConsent
                                    win.OCM.hasGoogleConsent = hasGoogleConsent;
                                    win.OCM.serveNonPersonalized = serveNonPersonalized;
                                    win.OCM.restrictDataProcessing = rdp;
                                }

                                if (config.debug || config.services.iab_compliance.debug) {
                                    console.log(LOG_PREFIX + 'GDPR applies', win.OCM)
                                }

                                // Service :: GPT
                                if (config.services.gpt.active) {
                                    const gpt = require('../services/gpt')
                                    gpt(config)
                                }
                                // -- Service :: GPT

                                win.googletag.cmd.push(() => {
                                    win.googletag.pubads().setPrivacySettings({
                                        nonPersonalizedAds: serveNonPersonalized,
                                        restrictDataProcessing: rdp
                                    })
                                });

                                end(true)
                            }
                        }
                    })
                } else if (typeof win.__cmp !== "undefined") {
                    if (config.debug || config.services.iab_compliance.debug) {
                        console.log(LOG_PREFIX + 'cmp api found, run ads normally')
                    }
                    end(true)
                } else {
                    if (config.debug || config.services.iab_compliance.debug) {
                        console.log(LOG_PREFIX + 'no iab api found,nonPersonalizedAds true')
                    }

                    win.googletag.cmd.push(() => {
                        win.OCM.hasPurposeOneConsent = false
                        win.OCM.hasPurposeTwoConsent = false
                        win.OCM.hasPurposeThreeConsent = false
                        win.OCM.hasPurposeFourConsent = false
                        win.OCM.hasPurposeSevenConsent = false
                        win.OCM.hasPurposeNineConsent = false
                        win.OCM.hasPurposeTenConsent = false
                        win.OCM.hasGoogleConsent = false
                        win.OCM.serveNonPersonalized = true
                        win.OCM.restrictDataProcessing = true;

                        win.googletag.pubads().setPrivacySettings({
                            nonPersonalizedAds: true,
                            restrictDataProcessing: true
                        })
                    });

                    end(true)
                }
            }
        }

        waitForTcfApi()
    })
}

let transmuteCmpScripts = (config) => {
    let new_script;
    let old_attr;
    let attr;
    let win = (config.use_window_top) ? window.top : window;
    let cmpSrcs = win.document.querySelectorAll('script[data-cmp-src]');
    if (cmpSrcs.length) {
        for (const script of cmpSrcs) {
            new_script = document.createElement('script');
            old_attr = Array.prototype.slice.call(script.attributes);
            while (attr = old_attr.pop()) {
                if (attr.nodeName === 'data-cmp-src') {
                    new_script.setAttribute('src', attr.nodeValue)
                } else {
                    new_script.setAttribute(attr.nodeName, attr.nodeValue)
                }
            }

            script.parentNode.insertBefore(new_script, script);
            script.remove()
        }
    }
    let cmpScripts = win.document.querySelectorAll('script[data-cmp-script]');
    if (cmpScripts.length) {
        for (const script of cmpScripts) {
            new_script = document.createElement('script')
            old_attr = Array.prototype.slice.call(script.attributes)
            while (attr = old_attr.pop()) {
                if (attr.nodeName === 'data-cmp-script') {
                    new_script.setAttribute('type', 'text/javascript')
                } else if (attr.nodeName !== 'type') {
                    new_script.setAttribute(attr.nodeName, attr.nodeValue)
                }
            }

            new_script.text = script.text
            script.parentNode.insertBefore(new_script, script)
            script.remove()
        }
    }
}

module.exports = iabCompliance
